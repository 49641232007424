import {
  ElectricitySupplyService,
  EnerbitPagination,
  Estate,
} from "@enerbit/base";
import * as singleSpa from "single-spa";
import { Frontier } from "../../models/assignees/frontiers";
import { Service } from "../../models/electricity-supply-services/ServiceInformation";

const mountedAppSingleSpaaccount = (
  idServiceAccount: string,
  shouldShowNewUser: boolean,
  showDetail: boolean,
  onClickDetail?: (user_id: string) => void
) => {
  singleSpa.registerApplication(
    "app-access-management",
    (): Promise<any> =>
      System.import("@enerBit/access-management").catch((e) =>
        console.log("error")
      ),
    (location) => location.pathname.startsWith("/"),
    {
      idServiceAccount: idServiceAccount,
      shouldShowNewUser: shouldShowNewUser,
      showDetail: showDetail,
      onClickDetail: onClickDetail,
    }
  );
};

export const mountedDisassembleaccount = async (
  idServiceAccount: string,
  shouldShowNewUser: boolean,
  showDetail: boolean,
  onClickDetail?: (user_id: string) => void
) => {
  const appNamesAccess = singleSpa
    .getAppNames()
    .find((item) => item == "app-access-management");

  if (appNamesAccess) {
    singleSpa
      .unregisterApplication("app-access-management")
      .then(() =>
        mountedAppSingleSpaaccount(
          idServiceAccount,
          shouldShowNewUser,
          showDetail,
          onClickDetail
        )
      );
  } else {
    mountedAppSingleSpaaccount(
      idServiceAccount,
      shouldShowNewUser,
      showDetail,
      onClickDetail
    );
  }
};

export interface ParamsServiceCreateMicro {
  leadUserEmail?: string;
  serviceAccountId: string;
  userId: string;
  onCloseModal: (electricitySupplyService: ElectricitySupplyService) => void;
  service: Service;
  estate: Estate | null;
  frontiers?: EnerbitPagination<Frontier> | null;
}

const mountedAppSingleSpaElectricityService = (
  params: ParamsServiceCreateMicro
) => {
  singleSpa.registerApplication(
    "app-electricity-supply-service-create",
    (): Promise<singleSpa.LifeCycles> =>
      System.import("@enerBit/electricity-supply-service-create"),
    (location) => location.pathname.startsWith("/"),
    params
  );
};

export const mountedApps = async (params: ParamsServiceCreateMicro) => {
  const appNamesAccess = singleSpa
    .getAppNames()
    .find((item) => item == "app-electricity-supply-service-create");

  if (appNamesAccess) {
    singleSpa
      .unregisterApplication("app-electricity-supply-service-create")
      .then(async () => {
        await mountedAppSingleSpaElectricityService(params);
      });
  } else {
    await mountedAppSingleSpaElectricityService(params);
  }
};
