import { enerbitColors } from "@enerbit/base";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { Box, Button, Typography } from "@mui/material";

const TitleComponent = ({
  color = `${enerbitColors.neutral[700]}`,
  titleText,
  IconTitle,
  showButtonTop,
  onClick,
}: {
  color?: string;
  titleText: string;
  IconTitle: React.ElementType;
  showButtonTop?: boolean;
  onClick?: () => void;
}) => (
  <Box sx={{ flexGrow: 1, marginRight: "0.5rem" }}>
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      <Typography sx={{ display: "flex", alignItems: "center" }}>
        <IconTitle sx={{ color: color, fontSize: "17px" }} />{" "}
        <span
          style={{
            color: color,
            fontWeight: "bold",
            marginLeft: "7px",
            fontSize: "1rem",
          }}
        >
          {titleText}
        </span>
      </Typography>
      {showButtonTop && (
        <Button
          onClick={onClick}
          sx={{
            padding: "0.3rem !important",
            justifyContent: "flex-end",
            minWidth: "0 !important",
          }}
        >
          <Box
            sx={{
              background: "transparent",
              padding: "0.2rem",
              borderRadius: "15%",
              display: "flex",
              border: `1px solid ${color}`,
            }}
          >
            <EditOutlinedIcon sx={{ color: color, fontSize: "17px" }} />{" "}
          </Box>
        </Button>
      )}
    </Box>
    <Box
      sx={{
        borderBottom: `0.8px solid ${color}`,
        margin: "0.5rem 0",
      }}
    ></Box>
  </Box>
);

export default TitleComponent;
