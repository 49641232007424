import {
  GridColumns,
  GridRenderCellParams,
  GridValueGetterParams,
  Typography,
  capitalizeFirstLetter,
  formatTwoDecimals,
  formatterPeso,
} from "@enerbit/base";
import moment from "moment";
import {
  EnerbitElectricitySupplySettlement,
  FixedEnerbitElectricitySupplySettlement,
  InvoiceInformation,
  Settlement,
  SettlementComponents,
  UnitCost,
} from "../../../../../../models/settlement/Settlement";

const getFixedEnerbitElectricitySupplySettlement = (
  params: GridValueGetterParams<FixedEnerbitElectricitySupplySettlement, Settlement>
) => {
  let amount: number | string | undefined;
  if (!params.row.fixed_enerbit_electricity_supply_settlement?.components?.fixed_settlements) {
    amount = "N/A";
    return amount;
  }
  amount = formatterPeso.format(
    params.row.fixed_enerbit_electricity_supply_settlement?.components?.fixed_settlements?.amount
  );
  return amount;
};

const getEnerbitElectricitySupplySettlement = (
  params: GridValueGetterParams<EnerbitElectricitySupplySettlement, Settlement>
) => {
  let agreed_price: number | undefined;
  if (
    (params.row.enerbit_electricity_supply_settlement?.components?.k_active_energy_settlement?.details?.intervals
      ?.length ?? -1) > 0
  ) {
    agreed_price =
      params.row.enerbit_electricity_supply_settlement?.components?.k_active_energy_settlement?.details?.intervals[0]
        ?.agreed_price;
  }
  return agreed_price;
};

const getInvoiceInformation = (params: GridValueGetterParams<InvoiceInformation[], Settlement>) => {
  const hasAny = params.row.invoice_information?.some((invoice) => {
    if (!invoice) {
      return false;
    }
    return invoice.payment_date === null || invoice.payment_date === undefined;
  });
  return hasAny ? "Pendiente" : "Pagado";
};

const getLegalPayableAmount = (params: GridValueGetterParams<Date, Settlement>) => {
  const total_month = formatterPeso.format(
    params.row.invoice_information !== undefined
      ? params.row.invoice_information?.reduce(
          (partialSum: number, i: InvoiceInformation) => partialSum + (i?.legal_payable_amount ?? 0),
          0
        )
      : 0
  );
  return total_month;
};

const getLiquidatedDays = (params: GridValueGetterParams<Date, Settlement>) => {
  const liquidated_days = moment(params.row.end).diff(params.row.start, "days");
  return liquidated_days;
};

export const columnsHistoryTable: GridColumns<Settlement> = [
  { field: "id", headerName: "Id", width: 70, hide: true },
  {
    field: "start",
    headerName: "Periodo liquidado",
    type: "date",
    width: 180,
    renderCell: (params: GridRenderCellParams<Date, Settlement>) => (
      <Typography variant="body1">{capitalizeFirstLetter(moment(params.value).format("MMMM/YYYY"))}</Typography>
    ),
  },
  {
    field: "created_at",
    headerName: "Total mes",
    type: "number",
    renderCell: (params: GridRenderCellParams<Date, Settlement>) => {
      return (
        <Typography variant="body1">
          {getLegalPayableAmount(params as GridValueGetterParams<Date, Settlement>)}
        </Typography>
      );
    },
    valueGetter: (params: GridRenderCellParams<Date, Settlement>) => {
      return getLegalPayableAmount(params as GridValueGetterParams<Date, Settlement>);
    },
  },
  {
    field: "components",
    headerName: "kWh",
    type: "number",
    renderCell: (params: GridRenderCellParams<SettlementComponents, Settlement>) => (
      <Typography variant="body1">
        {formatTwoDecimals.format(params.row.components?.active_energy_settlement?.quantity as number)}
      </Typography>
    ),
    valueGetter: (params) => params.row.components?.active_energy_settlement?.quantity,
  },
  {
    field: "invoice_information",
    headerName: "Estado factura asociada",
    width: 200,
    renderCell: (params: GridRenderCellParams<InvoiceInformation[], Settlement>) => {
      return (
        <Typography variant="body1">
          {getInvoiceInformation(params as GridValueGetterParams<InvoiceInformation[], Settlement>)}
        </Typography>
      );
    },
    valueGetter: (params: GridRenderCellParams<InvoiceInformation[], Settlement>) => {
      return getInvoiceInformation(params as GridValueGetterParams<InvoiceInformation[], Settlement>);
    },
  },
  {
    field: "end",
    headerName: "Días liquidados",
    type: "datetime",
    width: 130,
    renderCell: (params: GridRenderCellParams<Date, Settlement>) => {
      return (
        <Typography variant="body1">{getLiquidatedDays(params as GridValueGetterParams<Date, Settlement>)}</Typography>
      );
    },
    valueGetter: (params: GridRenderCellParams<Date, Settlement>) => {
      return getLiquidatedDays(params as GridValueGetterParams<Date, Settlement>);
    },
  },
  {
    field: "unit_cost",
    headerName: "CU/kWh",
    renderCell: (params: GridRenderCellParams<UnitCost, Settlement>) => {
      return <Typography variant="body1">{params.row.unit_cost?.cu_value}</Typography>;
    },
    valueGetter: (params) => params.row.unit_cost?.cu_value,
  },
  {
    field: "enerbit_electricity_supply_settlement",
    headerName: "G+C",
    renderCell: (params: GridRenderCellParams<EnerbitElectricitySupplySettlement, Settlement>) => {
      return (
        <Typography variant="body1">
          {getEnerbitElectricitySupplySettlement(
            params as GridValueGetterParams<EnerbitElectricitySupplySettlement, Settlement>
          )}
        </Typography>
      );
    },
    valueGetter: (params: GridRenderCellParams<EnerbitElectricitySupplySettlement, Settlement>) => {
      return getEnerbitElectricitySupplySettlement(
        params as GridValueGetterParams<EnerbitElectricitySupplySettlement, Settlement>
      );
    },
  },
  {
    field: "fixed_enerbit_electricity_supply_settlement",
    headerName: "Fijo",
    renderCell: (params: GridRenderCellParams<FixedEnerbitElectricitySupplySettlement, Settlement>) => {
      return (
        <Typography variant="body1">
          {getFixedEnerbitElectricitySupplySettlement(
            params as GridValueGetterParams<FixedEnerbitElectricitySupplySettlement, Settlement>
          )}
        </Typography>
      );
    },
    valueGetter: (params: GridValueGetterParams<FixedEnerbitElectricitySupplySettlement, Settlement>) => {
      return getFixedEnerbitElectricitySupplySettlement(
        params as GridValueGetterParams<FixedEnerbitElectricitySupplySettlement, Settlement>
      );
    },
  },
  // {
  //   field: "settlement_measurements_interval",
  //   headerName: "Lectura inicial",
  //   renderCell: (params: GridRenderCellParams<FixedEnerbitElectricitySupplySettlement, Settlement>) => (
  //     <Typography variant="body1">
  //       {formatTwoDecimals.format(
  //         (params.row.settlement_measurements_interval.initial_measurements_settlement! / 1000) as number
  //       )}
  //     </Typography>
  //   ),
  // },
  // {
  //   field: "settlement_measurements_interval_end",
  //   headerName: "Lectura final",
  //   renderCell: (params: GridRenderCellParams<FixedEnerbitElectricitySupplySettlement, Settlement>) => (
  //     <Typography variant="body1">
  //       {formatTwoDecimals.format(
  //         (params.row.settlement_measurements_interval.end_measurements_settlement! / 1000) as number
  //       )}
  //     </Typography>
  //   ),
  // },
];
