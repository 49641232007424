import { api } from "@enerbit/base";
import type { AnyAction, PayloadAction } from "@reduxjs/toolkit";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosInstance } from "axios";
import { Settlement } from "../../models/settlement/Settlement";
import { SettlementsState } from "../../models/states/SettlementsState";
import { UnitCost } from "../../models/unit-cost/UnitCost";
import { ReadSubsidyRate } from "../../models/unit-cost/utils/ReadSubsidyRate";

//Obtener settlements (liquidaciones) de un electricity supply service
export const getSettlements = createAsyncThunk(
  //action type string
  "settlements/getSettlements",
  //callback function
  async (id: string, thunkAPI): Promise<Settlement[] | any> => {
    try {
      const { data } = await (api as AxiosInstance).get(
        `/electricity-supply-service/cas/settlements/electricity-supply-services/?electricity_supply_service_id=${id}`
      );
      return data;
    } catch (error: any) {
      console.error(error);
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);

//Funcion para obtener tarifas del settlement
export const getUnitCost = createAsyncThunk(
  //action type string
  "settlements/getUnitCost",
  //callback function
  async (uc_id: string): Promise<UnitCost | null> => {
    try {
      const { data } = await (api as AxiosInstance).get(
        `electricity-supply-service/tariff-manager/unit-cost/read-unit-cost/?id=${uc_id}`
      );
      return data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }
);

export const getIncumbentUnitCost = createAsyncThunk(
  //action type string
  "settlements/getIncumbentUnitCost",
  //callback function
  async (uc_id: string): Promise<UnitCost | null> => {
    try {
      const { data } = await (api as AxiosInstance).get(
        `electricity-supply-service/tariff-manager/unit-cost/read-unit-cost/?id=${uc_id}`
      );
      return data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }
);

export const getIncumbentSubsidyRate = createAsyncThunk(
  //action type string
  "settlements/getIncumbentSubsidyRate",
  //callback function
  async (subsidy_rate_id: string): Promise<ReadSubsidyRate | null> => {
    try {
      const { data } = await (api as AxiosInstance).get(
        `electricity-supply-service/tariff-manager/utils/read-subsidy-rate/?id=${subsidy_rate_id}`
      );
      return data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }
);

const initialState: SettlementsState = {
  settlements: [] as Settlement[],
  selectedSettlement: {} as Settlement,
  unitCost: {} as UnitCost,
  incumbentUnitCost: {} as UnitCost,
  incumbentSubsidyRate: {} as ReadSubsidyRate,
  electricitySupplyServiceId: "3c32f7c0-660b-11ed-8a9c-000000000000",
  isLoadingSettlements: false,
  openHistoryDialog: false,
  isLoadingUnitCost: false,
  settlementsError: "",
};

export const settlementSlice = createSlice({
  name: "settlements",
  initialState,
  //Funciones para modificar el estado
  reducers: {
    setOpenHistoryDialog: (state, action: PayloadAction<boolean>) => {
      state.openHistoryDialog = action.payload;
    },
    getLastSettlement: (state: SettlementsState) => {
      state.selectedSettlement = {} as Settlement;
      //Get the most recent settlement
      state.selectedSettlement = state.settlements?.sort(
        (a: Settlement, b: Settlement) =>
          //@ts-ignore
          +new Date(b.settlement_period) - +new Date(a.settlement_period)
      )[0];
    },
    setSelectedSettlement: (state, action: PayloadAction<Settlement>) => {
      state.selectedSettlement = action.payload;
    },
    cleanUnitCost: (state) => {
      state.unitCost = {} as UnitCost;
    },
    cleanIncumbentUnitCost: (state) => {
      state.incumbentUnitCost = {} as UnitCost;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getSettlements.pending.type, (state) => {
        state.isLoadingSettlements = true;
        state.settlements = [];
      })
      .addCase(getSettlements.fulfilled.type, (state, action: PayloadAction<Settlement[]>) => {
        state.isLoadingSettlements = false;
        state.settlements = action.payload;
      })
      .addCase(getSettlements.rejected.type, (state, { payload }: AnyAction) => {
        state.isLoadingSettlements = false;
        if (payload.status === 404) {
          state.settlementsError = "No se encontraron liquidaciones.";
        } else if (payload.status === 500) {
          state.settlementsError = "Error del servidor.";
        } else {
          state.settlementsError = "Error desconocido del servidor, no se pudo obtener las liquidaciones.";
        }
      })
      .addCase(getUnitCost.pending.type, (state) => {
        state.isLoadingUnitCost = true;
        state.unitCost = {} as UnitCost;
      })
      .addCase(getUnitCost.fulfilled.type, (state, action: PayloadAction<UnitCost>) => {
        state.isLoadingUnitCost = false;
        state.unitCost = action.payload;
      })
      .addCase(getUnitCost.rejected.type, (state, action) => {
        state.isLoadingUnitCost = false;

        state.unitCost = {} as UnitCost;
      })
      .addCase(getIncumbentUnitCost.pending.type, (state, action) => {
        state.incumbentUnitCost = {} as UnitCost;
      })
      .addCase(getIncumbentUnitCost.fulfilled.type, (state, action: PayloadAction<UnitCost>) => {
        state.isLoadingUnitCost = false;
        state.incumbentUnitCost = action.payload;
      })
      .addCase(getIncumbentUnitCost.rejected.type, (action) => {})
      //Obtener la tasa de subsidio del Incumbente
      .addCase(getIncumbentSubsidyRate.pending.type, (state, action) => {
        state.incumbentSubsidyRate = {} as ReadSubsidyRate;
      })
      .addCase(getIncumbentSubsidyRate.fulfilled.type, (state, action: PayloadAction<ReadSubsidyRate>) => {
        state.incumbentSubsidyRate = action.payload;
      })
      .addCase(getIncumbentSubsidyRate.rejected.type, (action) => {});
  },
});
export const { setOpenHistoryDialog, getLastSettlement, setSelectedSettlement, cleanUnitCost, cleanIncumbentUnitCost } =
  settlementSlice.actions;
export default settlementSlice.reducer;
