import { Box, styled, Typography } from "@enerbit/base";
import { useState } from "react";
import { useSelector } from "react-redux";
import { getIncumbentName } from "../../../../helpers/settlements/getIncumbentName";
import { StateStorage } from "../../../../models/StateStorage";

import SettlementCards from "./settlement-cards/SettlementCards";

const MonthlySettlement = () => {
  const [checked, setChecked] = useState(false);

  const { settlementsError, selectedSettlement, unitCost } = useSelector(
    (state: StateStorage) => state.settlements
  );

  return (
    <>
      {!checked && !settlementsError && (
        <SettlementCards isEnerbit={true} checked={checked} />
      )}
      {checked && !settlementsError && (
        <Box
          display="flex"
          gap="2rem"
          sx={{
            "@media (max-width: 1020px)": {
              flexDirection: "column",
            },
          }}
        >
          <Box width="100%">
            <TypographyStyled>enerBit</TypographyStyled>
            <SettlementCards isEnerbit={true} checked={checked} />
          </Box>
          <Box width="100%">
            <TypographyStyled>
              {getIncumbentName(unitCost?.market_sui_code)}{" "}
            </TypographyStyled>
            <SettlementCards isEnerbit={false} checked={checked} />
          </Box>
        </Box>
      )}

      {/* {!settlementsError && selectedSettlement && (
        <>
          <CompareWithIncumbentContainer>
            <Typography
              color={theme.palette.primary.main}
              sx={{ fontSize: "22px", fontWeigth: 700 }}
            >
              Comparar con incumbente
            </Typography>
            <Switch
              color="secondary"
              checked={checked}
              onChange={() => setChecked(!checked)}
              inputProps={{ "aria-label": "controlled" }}
            />
          </CompareWithIncumbentContainer>
        </>
      )} */}
    </>
  );
};

export default MonthlySettlement;

const MonthAndButtonContainer = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 0px 24px 0px;
`;

const CompareWithIncumbentContainer = styled("div")`
  display: flex;
  justify-content: space-between;
  padding-top: 24px;
  padding-bottom: 24px;
`;

const TypographyStyled = styled(Typography)`
  color: #53358e;
  font-weight: 700;
  font-size: 22px;
  margin-bottom: 20px;
`;
