import {
  Box,
  CircularProgress,
  CustomAlert,
  DataGrid,
  GridCallbackDetails,
  GridEventListener,
  GridRowParams,
  GridToolbar,
  MuiEvent,
  Pagination,
  Typography,
  esES,
  gridPageCountSelector,
  gridPageSelector,
  moment,
  theme,
  useGridApiContext,
  useGridSelector,
} from "@enerbit/base";

import { useDispatch, useSelector } from "react-redux";
import { StateStorage } from "../../../../models/StateStorage";
import {
  setOpenHistoryDialog,
  setSelectedSettlement,
} from "../../../../store/slices/settlementSlice";
import HistoryDialog from "../monthly-settlement/history/HistoryDialog";
import { columnsHistoryTable } from "../monthly-settlement/history/utils/ColumnsHistoryTable";
moment.locale("es");

function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Pagination
      shape="rounded"
      color="primary"
      count={pageCount}
      page={page + 1}
      onChange={(event: any, value: any) => apiRef.current.setPage(value - 1)}
    />
  );
}

const MonthAndButton = () => {
  //Estado para el Dialog de historial
  const dispatch = useDispatch();
  const {
    openHistoryDialog,
    settlements,
    selectedSettlement,
    settlementsError,
    isLoadingSettlements,
  } = useSelector((state: StateStorage) => state.settlements);
  const primary_color = theme.palette.primary.main;

  //Al hacer click en una liquidcion de la tabla
  const clickTestRow: GridEventListener<"rowClick"> | any = (
    params?: GridRowParams,
    event?: MuiEvent<React.MouseEvent<HTMLElement, MouseEvent>>,
    details?: GridCallbackDetails
  ) => {
    //Si al settlement que se le dio click es el mismo que el que ya esta seleccionado, no se hace nada
    // if (params?.row?.id === selectedSettlement?.id) {
    //   return;
    // }
    dispatch(setSelectedSettlement(params?.row));
    dispatch(setOpenHistoryDialog(true));

    return;
  };

  return (
    <>
      <Box className="Container-history-settlement">
        <Box>
          <Typography
            color={theme.palette.primary.main}
            sx={{ fontSize: "1.5rem", fontWeight: "400", lineHeight: "1.5" }}
          >
            Histórico de liquidaciones<span>&nbsp;</span>
          </Typography>
          <Typography
            className="History-settlement-text"
            sx={{ fontSize: "1.125rem", fontWeight: "400" }}
          >
            Selecciona una liquidación para ver más detalle
          </Typography>
        </Box>
      </Box>
      {selectedSettlement && (
        <>
          <DataGrid
            disableSelectionOnClick={true}
            className="mySpecificDataGrid" // Añade una clase específica aquí
            rows={settlements}
            columns={columnsHistoryTable}
            pageSize={5}
            rowsPerPageOptions={[5]}
            autoHeight={true}
            onCellClick={clickTestRow}
            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
            pagination
            components={{
              Pagination: CustomPagination,
              Toolbar: GridToolbar,
            }}
            sx={{
              borderRadius: "20px",
              "& .MuiDataGrid-row:hover": {
                cursor: "pointer",
                color: "white !important",
              },

              "& .MuiDataGrid-cell:focus": {
                outline: "none",
              },
              "& .MuiDataGrid-cell:focus-within": {
                outline: "none",
              },
              "& .MuiDataGrid-virtualScrollerContent": {
                backgroundColor: "var(--color-neutral100)",
              },
              "& .MuiDataGrid-footerContainer": {
                justifyContent: "center",
              },
              "& .MuiDataGrid-columnHeaderTitle": {
                textOverflow: "inherit",
              },
            }}
          />
        </>
      )}
      {!selectedSettlement && !settlementsError && isLoadingSettlements && (
        <>
          <Box display="flex" gap="1rem" sx={{ alignItems: "center" }}>
            <Typography color={theme.palette.primary.main}>
              Cargando liquidaciones
            </Typography>
            <CircularProgress />
          </Box>
        </>
      )}
      {settlementsError && (
        <CustomAlert
          severity="error"
          text={settlementsError}
          onClose={() => null}
        />
      )}
      {settlements?.length === 0 &&
        !settlementsError &&
        !isLoadingSettlements && (
          <CustomAlert
            severity="info"
            text="No hay liquidaciones disponibles"
            onClose={() => null}
            sx={{ background: "#F5F5F5" }}
          />
        )}
      {/* Modal de Historial de Liquidaciones */}
      <HistoryDialog
        openHistoryDialog={openHistoryDialog}
        setOpenHistoryDialog={setOpenHistoryDialog}
      />
    </>
  );
};

export default MonthAndButton;
