import { styled } from "@enerbit/base";
import { useSelector } from "react-redux";
import LeftMenu from "../common/LeftMenu/LeftMenu";
import ListTabs from "../common/Tabs/ListTabs";
import { StateStorage } from "../models/StateStorage";
import { TabModel } from "../models/ui/Tab";
import { Information } from "./information/Information";
import SettlementsPage from "./settlements/components/SettlementsPage";
import UsagesPage from "./usages/components/UsagesPage";

const AttentionPage = () => {
  const { service } = useSelector((state: StateStorage) => state.information);

  const tabs: TabModel[] = [
    {
      label: "Liquidaciones",
      component: <SettlementsPage />,
    },
    {
      label: "Consumos",
      component: <UsagesPage />,
      disabled: service?.started_at ? false : true,
    },
    {
      label: "Información",
      component: <Information />,
    },
    // {
    //   label: "Órdenes de servicio",
    //   component: <ServicesOrdersPage />,
    // },
  ];

  return (
    <AttentionPageContainer>
      <LeftMenu />
      <ContentContainer>
        <ListTabs tabs={tabs} />
      </ContentContainer>
    </AttentionPageContainer>
  );
};

export default AttentionPage;

const AttentionPageContainer = styled("div")`
  display: flex;
  flex-direction: column;
  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const ContentContainer = styled("div")`
  padding: 1.5rem 2.25rem 2.25rem 2rem;
  width: 100%;
`;
