import { EnerbitPagination, api } from "@enerbit/base";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosInstance } from "axios";
import { Frontier } from "../../../models/assignees/frontiers";

export const getFrontiers = createAsyncThunk(
  //action type string
  "[Assignees] Get Frontiers",
  //callback function
  async (measurement_point_id: string, thunkAPI): Promise<EnerbitPagination<Frontier> | any> => {
    try {
      const { data } = await (api as AxiosInstance).get(
        `assignees/frontiers?measurement_point_id=${measurement_point_id}`
      );
      return data;
    } catch (error: any) {
      console.error(error);
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);
