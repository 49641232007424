// getEnerbitElectricitySupplyService

import { EnerbitPagination } from "@enerbit/base";
import { AnyAction, PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Frontier } from "../../models/assignees/frontiers";
import { AssigneesState } from "../../models/states/AssigneesState";
import { getFrontiers } from "../actions/assignees/assignees.actions";

const initialState: AssigneesState = {
  enerbitFrontiers: null,
  isLoadingEnerbitFrontiers: false,
  enerbitFrontiersError: null,
};

export const assigneesSlice = createSlice({
  name: "assignees",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getFrontiers.pending.type, (state) => {
      state.isLoadingEnerbitFrontiers = true;
    });
    builder.addCase(getFrontiers.rejected.type, (state, { payload }: AnyAction) => {
      state.isLoadingEnerbitFrontiers = false;
      switch (payload.status) {
        case 404:
          state.enerbitFrontiersError = "Error, no se encontraron fronteras.";
          break;
        case 400:
        case 401:
        case 402:
        case 403:
        case 405:
          state.enerbitFrontiersError = "Error del cliente.";
          break;
        case 500:
          state.enerbitFrontiersError = "Error del servidor, no se pudieron obtener las fronteras.";
          break;
        default:
          state.enerbitFrontiersError = "Error desconocido del servidor, no se pudieron obtener las fronteras.";
          break;
      }
    });
    builder.addCase(getFrontiers.fulfilled.type, (state, action: PayloadAction<EnerbitPagination<Frontier>>) => {
      state.isLoadingEnerbitFrontiers = false;
      state.enerbitFrontiers = action.payload;
    });
  },
});

export default assigneesSlice.reducer;
export const {} = assigneesSlice.actions;
