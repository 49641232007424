import { Box, ErrorOutlineIcon, formatterPeso, styled, theme, Typography } from "@enerbit/base";
import { SettlementCardRowProps } from "../../../../../models/props/SettlementCardRow";

const SettlementCardRow = ({ isPrincipal, title, fontWeight = 400, value1, value2 }: SettlementCardRowProps) => {
  return (
    <ServiceAndValues>
      <NameAndIcon>
        <ErrorOutlineIcon fontSize="small" />
        <Typography sx={{ fontSize: "14px", fontWeight }}>{title}</Typography>
      </NameAndIcon>
      <Box style={{ width: "20%" }} sx={{ display: "flex", flexDirection: "column" }}>
        {isPrincipal && (
          <Typography sx={{ fontWeight: 400 }} color={theme.palette.primary.main}>
            Valor unitario
          </Typography>
        )}
        <Typography sx={{ fontWeight }}>
          {value1 || value1 == 0 ? formatterPeso.format(value1 as number) : ""}
        </Typography>
      </Box>
      <Box style={{ width: "20%" }} sx={{ display: "flex", flexDirection: "column" }}>
        {isPrincipal && (
          <Typography sx={{ fontWeight: 400 }} color={theme.palette.primary.main}>
            Total (multiplicado por consumo)
          </Typography>
        )}
        <Typography sx={{ fontWeight }}>
          {value2 || value2 == 0 ? formatterPeso.format(value2 as number) : ""}
        </Typography>
      </Box>
    </ServiceAndValues>
  );
};

export default SettlementCardRow;

const ServiceAndValues = styled("div")`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 10px 0px 10px 0px;

  &:last-child {
    margin-bottom: 0;
  }
  align-items: center;
`;
const NameAndIcon = styled("div")`
  display: flex;
  gap: 0.5rem;
  width: 60%;

  &:hover {
    color: var(--primary);
  }
`;
