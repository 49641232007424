import { formatterPeso, formatTwoDecimals, styled, theme, Typography } from "@enerbit/base";
import { useSelector } from "react-redux";
import { InvoiceInformation } from "../../../../models/settlement/Settlement";
import { StateStorage } from "../../../../models/StateStorage";

const Cards = () => {
  const { selectedSettlement, settlementsError, settlements } = useSelector((state: StateStorage) => state.settlements);

  const agreed_price =
    selectedSettlement?.enerbit_electricity_supply_settlement?.components?.k_active_energy_settlement?.details
      ?.intervals !== null
      ? selectedSettlement?.enerbit_electricity_supply_settlement?.components?.k_active_energy_settlement?.details
          ?.intervals[0]?.agreed_price
      : 0;

  return (
    <CardsContainer>
      {!settlementsError && settlements?.length !== 0 && (
        <>
          <Card
            sx={{
              background: "#fef1e6",
            }}
          >
            <TypographyLabel color={theme.palette.secondary.main}>Total</TypographyLabel>
            <TypographyValue color={theme.palette.secondary.main}>
              {formatterPeso.format(
                selectedSettlement?.invoice_information !== undefined
                  ? selectedSettlement?.invoice_information?.reduce(
                      (partialSum: number, i: InvoiceInformation) => partialSum + (i?.legal_payable_amount ?? 0),
                      0
                    )
                  : 0
              )}
            </TypographyValue>
          </Card>
          <Card>
            <TypographyLabel color={theme.palette.primary.main}>Consumo</TypographyLabel>
            <TypographyValue color={theme.palette.primary.main}>
              {formatTwoDecimals.format(selectedSettlement?.components?.active_energy_settlement?.quantity as number)}{" "}
              {"kWh"}
            </TypographyValue>
          </Card>
          <Card>
            <TypographyLabel color={theme.palette.primary.main}>Tarifa publicada en el mes:</TypographyLabel>
            {/* Usar punto en decimal */}
            <TypographyValue color={theme.palette.primary.main}>
              {formatterPeso.format(selectedSettlement?.unit_cost?.cu_value as any)} /kWh
            </TypographyValue>
          </Card>
          <Card>
            <TypographyLabel color={theme.palette.primary.main}>Plan</TypographyLabel>
            <TypographyValue color={theme.palette.primary.main}>
              G+C: {agreed_price ? formatterPeso.format(agreed_price) : "Sin cobertura"}{" "}
            </TypographyValue>
            <TypographyValue color={theme.palette.primary.main}>
              Fijo:{" "}
              {`${formatterPeso.format(
                selectedSettlement?.fixed_enerbit_electricity_supply_settlement?.components?.fixed_settlements
                  ?.amount ?? 0
              )} + IVA`}
            </TypographyValue>
          </Card>
        </>
      )}
    </CardsContainer>
  );
};

export default Cards;
const CardsContainer = styled("div")`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  margin-bottom: 20px;
`;
const Card = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  background: #f2f4f7;
  padding: 20px;
  border-radius: 16px;
`;

const TypographyValue = styled(Typography)`
  color: primary;
  font-weight: 700;
  font-size: 16px;
`;

const TypographyLabel = styled(Typography)`
  color: primary;
  font-size: 16px;
`;
