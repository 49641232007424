import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { isEmptyObject } from "../../../helpers/settlements/isEmptyObject";
import { StateStorage } from "../../../models/StateStorage";
import { SettlementsState } from "../../../models/states/SettlementsState";
import {
  cleanIncumbentUnitCost,
  cleanUnitCost,
  getIncumbentSubsidyRate,
  getIncumbentUnitCost,
  getLastSettlement,
  getSettlements,
  getUnitCost,
} from "../../../store/slices/settlementSlice";
import { AppDispatch } from "../../../store/store";
import MonthAndButton from "./month-history-button/MonthAndButton";

const SettlementsPage = () => {
  const { email, id } = useParams();

  const dispatch = useDispatch<AppDispatch>();

  const { settlements, selectedSettlement, unitCost } = useSelector(
    (state: StateStorage): SettlementsState => state.settlements
  );
  useEffect(() => {
    //Ejecutar cada vez que cambie el id del usuario
    if (id) {
      dispatch(getSettlements(id));
    }
  }, [id]);

  useEffect(() => {
    //Ejecutar cada vez que cambie la liquidacion seleccionada
    if (selectedSettlement?.components?.active_energy_settlement?.uc_id && isEmptyObject(unitCost?.components)) {
      dispatch(getUnitCost(selectedSettlement?.components?.active_energy_settlement?.uc_id));
    } else {
      dispatch(cleanUnitCost());
      dispatch(cleanIncumbentUnitCost());
    }

    if (selectedSettlement?.components?.active_energy_settlement_previous_retailers?.uc_id) {
      dispatch(
        getIncumbentUnitCost(selectedSettlement?.components?.active_energy_settlement_previous_retailers?.uc_id)
      );
    }

    //Obtener subsidy_rate del incumbente
    if (selectedSettlement?.components?.subsidy_active_energy_settlements?.subsidy_rate_id) {
      dispatch(
        getIncumbentSubsidyRate(selectedSettlement?.components?.subsidy_active_energy_settlements?.subsidy_rate_id)
      );
    }
  }, [selectedSettlement]);

  useEffect(() => {
    //   //Validar que la ultima liquidacion no este vacia
    //   //Si esta vacia que haga el ejecute el reducer de lo contrario no

    dispatch(getLastSettlement());
  }, [settlements, id, email]);

  return (
    <>
      <MonthAndButton />
    </>
  );
};

export default SettlementsPage;
