import {
  Box,
  IconButton,
  KeyboardArrowDownIcon,
  KeyboardArrowUpIcon,
  styled,
} from "@enerbit/base";
import { useSelector } from "react-redux";
import { SettlementCardsProps } from "../../../../../models/props/SettlementCards";
import { StateStorage } from "../../../../../models/StateStorage";

import { useState } from "react";
import SettlementCardRow from "./SettlementCardRow";

const SettlementCards = ({ isEnerbit, checked }: SettlementCardsProps) => {
  const {
    selectedSettlement,
    incumbentUnitCost,
    settlementsError,
    settlements,
    incumbentSubsidyRate,
  } = useSelector((state: StateStorage) => state.settlements);

  const [displayTariff, setDisplayTariff] = useState(false);
  const frequency_compensations =
    selectedSettlement?.components?.frequency_compensations?.amount;

  const duration_compensations =
    selectedSettlement?.components?.duration_compensations?.amount;

  const subsidy_active_energy_settlements =
    selectedSettlement?.components?.subsidy_active_energy_settlements?.amount;

  const contribution_active_energy_settlement =
    selectedSettlement?.components?.contribution_active_energy_settlement
      ?.amount;

  const consumption =
    selectedSettlement?.components?.active_energy_settlement?.quantity;

  const fixed =
    selectedSettlement?.fixed_enerbit_electricity_supply_settlement?.components
      ?.fixed_settlements?.amount;

  const g_value = selectedSettlement?.unit_cost?.g_value;
  const c_value = selectedSettlement?.unit_cost?.c_value;
  const t_value = selectedSettlement?.unit_cost?.t_value;
  const d_value = selectedSettlement?.unit_cost?.d_value;
  const pr_value = selectedSettlement?.unit_cost?.pr_value;
  const r_value = selectedSettlement?.unit_cost?.r_value;

  const gc =
    selectedSettlement?.enerbit_electricity_supply_settlement?.components
      ?.k_active_energy_settlement?.details?.intervals !== null
      ? selectedSettlement?.enerbit_electricity_supply_settlement?.components
          ?.k_active_energy_settlement?.details?.intervals[0]?.gc
      : 0;

  const agreed_price =
    selectedSettlement?.enerbit_electricity_supply_settlement?.components
      ?.k_active_energy_settlement?.details?.intervals !== null
      ? selectedSettlement?.enerbit_electricity_supply_settlement?.components
          ?.k_active_energy_settlement?.details?.intervals[0]?.agreed_price
      : 0;

  const iva =
    (selectedSettlement?.fixed_enerbit_electricity_supply_settlement?.components
      ?.fixed_settlements?.amount ?? 0) *
    (selectedSettlement?.fixed_enerbit_electricity_supply_settlement?.components
      ?.fixed_settlements?.tax_rate ?? 0);

  return (
    <Box>
      {!settlementsError && settlements?.length !== 0 && (
        <>
          <CardContainer>
            <CardHeader
              sx={{
                borderBottomLeftRadius: displayTariff ? "0rem" : "1rem",
                borderBottomRightRadius: displayTariff ? "0rem" : "1rem",
              }}
            >
              <SettlementCardRow
                isPrincipal={true}
                title="Tarifa publicada"
                fontWeight={700}
                value1={
                  isEnerbit
                    ? selectedSettlement?.unit_cost?.cu_value?.toFixed(2)
                    : incumbentUnitCost?.value?.toFixed(2)
                }
                value2={
                  isEnerbit
                    ? selectedSettlement?.components?.active_energy_settlement?.amount?.toFixed(
                        2
                      )
                    : selectedSettlement?.components?.active_energy_settlement_previous_retailers?.amount?.toFixed(
                        2
                      )
                }
              />
              <IconButton
                size="small"
                onClick={() => setDisplayTariff(!displayTariff)}
              >
                {displayTariff ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </IconButton>
            </CardHeader>
            {displayTariff && (
              <CardBody>
                <SettlementCardRow
                  title="G"
                  value1={
                    isEnerbit
                      ? g_value?.toFixed(2)
                      : incumbentUnitCost?.components?.g_component?.value?.toFixed(
                          2
                        )
                  }
                  value2={
                    isEnerbit
                      ? ((g_value ?? 0) * (consumption ?? 0))?.toFixed(2)
                      : (
                          incumbentUnitCost?.components?.g_component?.value *
                          (consumption ?? 0)
                        )?.toFixed(2)
                  }
                />
                <SettlementCardRow
                  title="C"
                  value1={
                    isEnerbit
                      ? c_value?.toFixed(2)
                      : incumbentUnitCost?.components?.c_component?.value
                  }
                  value2={
                    isEnerbit
                      ? ((c_value ?? 0) * (consumption ?? 0)).toFixed(2)
                      : (
                          incumbentUnitCost?.components?.c_component?.value *
                          (consumption ?? 0)
                        )?.toFixed(2)
                  }
                />
                <SettlementCardRow
                  title="T"
                  value1={
                    isEnerbit
                      ? t_value?.toFixed(2)
                      : incumbentUnitCost?.components?.t_component?.value?.toFixed(
                          2
                        )
                  }
                  value2={
                    isEnerbit
                      ? ((t_value ?? 0) * (consumption ?? 0))?.toFixed(2)
                      : (
                          incumbentUnitCost?.components?.t_component?.value *
                          (consumption ?? 0)
                        )?.toFixed(2)
                  }
                />
                <SettlementCardRow
                  title="D"
                  value1={
                    isEnerbit
                      ? d_value?.toFixed(2)
                      : incumbentUnitCost?.components?.d_component?.value?.toFixed(
                          2
                        )
                  }
                  value2={
                    isEnerbit
                      ? ((d_value ?? 0) * (consumption ?? 0))?.toFixed(2)
                      : (
                          incumbentUnitCost?.components?.d_component?.value *
                          (consumption ?? 0)
                        )?.toFixed(2)
                  }
                />
                <SettlementCardRow
                  title="P"
                  value1={
                    isEnerbit
                      ? pr_value?.toFixed(2)
                      : incumbentUnitCost?.components?.pr_component?.value
                  }
                  value2={
                    isEnerbit
                      ? ((pr_value ?? 0) * (consumption ?? 0))?.toFixed(2)
                      : (
                          incumbentUnitCost?.components?.pr_component?.value *
                          (consumption ?? 0)
                        )?.toFixed(2)
                  }
                />
                <SettlementCardRow
                  title="R"
                  value1={
                    isEnerbit
                      ? r_value?.toFixed(2)
                      : incumbentUnitCost?.components?.r_component?.value?.toFixed(
                          2
                        )
                  }
                  value2={
                    isEnerbit
                      ? (
                          (selectedSettlement?.unit_cost?.r_value ?? 0) *
                          (consumption ?? 0)
                        )?.toFixed(2)
                      : (
                          incumbentUnitCost?.components?.r_component?.value *
                          (consumption ?? 0)
                        )?.toFixed(2)
                  }
                />
              </CardBody>
            )}
          </CardContainer>
          <CardContainer>
            <CardHeader>
              <SettlementCardRow
                title="Otros cargos"
                fontWeight={700}
                value1={null}
                value2={null}
              />
            </CardHeader>
            <CardBody>
              <SettlementCardRow
                title="Compensación por frecuencia"
                value1={null}
                value2={frequency_compensations?.toFixed(2) ?? 0}
              />
              <SettlementCardRow
                title="Compensación por duración"
                value1={null}
                value2={duration_compensations?.toFixed(2) ?? 0}
              />
              <SettlementCardRow
                title="Contribución"
                value1={null}
                value2={
                  isEnerbit
                    ? (contribution_active_energy_settlement ?? 0)?.toFixed(2)
                    : (
                        (consumption ?? 0) *
                        (incumbentUnitCost?.value ?? 0) *
                        (selectedSettlement?.components
                          ?.contribution_active_energy_settlement
                          ?.contribution_rate ?? 0)
                      ).toFixed(2)
                }
              />
              <SettlementCardRow
                title="Subsidio"
                value1={null}
                value2={
                  isEnerbit
                    ? subsidy_active_energy_settlements?.toFixed(2) ?? 0
                    : (consumption ?? 0) *
                      (incumbentUnitCost?.value ?? 0) *
                      (-incumbentSubsidyRate.rate || 0)
                }
              />
            </CardBody>
          </CardContainer>
          <CardContainer>
            <CardHeader>
              <SettlementCardRow
                isPrincipal={true}
                title="Cobertura"
                fontWeight={700}
                value1={null}
                value2={null}
              />
            </CardHeader>
            <CardBody>
              <SettlementCardRow
                title="G+C fijaBit"
                value1={isEnerbit ? agreed_price : null}
                value2={
                  isEnerbit
                    ? ((agreed_price ?? 0) * (consumption ?? 0)).toFixed(2)
                    : null
                }
              />
              <SettlementCardRow
                title="Diferencia entre tarifa y cobertura"
                value1={
                  isEnerbit
                    ? ((agreed_price ?? 0) - (gc ?? 0)).toFixed(2)
                    : null
                }
                value2={
                  isEnerbit
                    ? (
                        selectedSettlement
                          ?.enerbit_electricity_supply_settlement?.components
                          ?.k_active_energy_settlement?.amount ?? 0
                      ).toFixed(2)
                    : null
                }
              />
              <SettlementCardRow
                title="Tarifa efectiva"
                value1={
                  isEnerbit
                    ? (
                        (selectedSettlement?.unit_cost?.cu_value ?? 0) +
                        (agreed_price ?? 0) -
                        (gc ?? 0)
                      ).toFixed(2)
                    : //Lo mismo que la publicada
                      incumbentUnitCost?.value?.toFixed(2)
                }
                value2={
                  isEnerbit
                    ? (
                        ((selectedSettlement?.unit_cost?.cu_value ?? 0) +
                          (agreed_price ?? 0) -
                          (gc ?? 0)) *
                        (consumption ?? 0)
                      ).toFixed(2)
                    : (incumbentUnitCost?.value * (consumption ?? 0)).toFixed(2)
                }
              />
              <SettlementCardRow
                title="Herramientas tecnológicas sin IVA"
                value1={null}
                value2={isEnerbit ? fixed?.toFixed(2) : null}
              />
              <SettlementCardRow
                title="IVA"
                value1={null}
                value2={isEnerbit ? iva.toFixed(2) : null}
              />
            </CardBody>
          </CardContainer>
        </>
      )}
    </Box>
  );
};
export default SettlementCards;

const CardContainer = styled("div")`
  border-radius: 1rem;
  margin-bottom: 20px;
`;

const CardBody = styled("div")`
  padding: 16px 1rem;
  background: #f2f4f7;
`;

const CardHeader = styled("div")`
  background-color: white;
  display: flex;
  justify-content: space-between;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  min-height: 49px;
  align-items: center;
  padding: 0 1rem;
  border: 1px solid #e4e7eb;
`;

const CardRow = styled("div")`
  display: flex;
  background: #f2f4f7;
`;
