import {
  Box,
  Button,
  capitalizeFirstLetter,
  Dialog,
  DialogActions,
  DialogContent,
  HighlightOffOutlinedIcon,
  theme,
  Typography,
} from "@enerbit/base";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { HistoryDialogProps } from "../../../../../models/props/HistoryDialog";
import { StateStorage } from "../../../../../models/StateStorage";
import { AppDispatch } from "../../../../../store/store";
import Cards from "../../insight-cards/Cards";
import MonthlySettlement from "../MonthlySettlement";

//Componente Modal que contiene la Tabla del Historial

const HistoryDialog = ({ openHistoryDialog, setOpenHistoryDialog }: HistoryDialogProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const { selectedSettlement } = useSelector((state: StateStorage) => state.settlements);
  return (
    <div>
      <Dialog
        open={openHistoryDialog}
        keepMounted
        onClose={() => dispatch(setOpenHistoryDialog(false))}
        fullWidth={true}
        PaperProps={{
          sx: { p: "10px 20px", borderRadius: "20px" },
        }}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "100%", // Set your width here
              height: "90vh",
            },
          },
        }}
      >
        <DialogActions sx={{ pb: 0 }}>
          <Button
            onClick={() => dispatch(setOpenHistoryDialog(false))}
            sx={{
              "& .MuiButtonBase-root": {
                padding: "5rem",
                borderRadius: "100%",
              },
            }}
          >
            <HighlightOffOutlinedIcon fontSize="large" />
          </Button>
        </DialogActions>
        <DialogContent>
          <Box className="Month-and-button-container">
            <Box>
              <Typography
                color={theme.palette.primary.main}
                sx={{ fontSize: "22px", fontWeight: "700", marginBottom: "1.6rem" }}
              >
                Liquidación:<span>&nbsp;</span>
                {`${capitalizeFirstLetter(moment(selectedSettlement?.start).format("MMMM / YYYY"))}`}
              </Typography>
              <Typography color={theme.palette.primary.main} sx={{ fontWeight: "400", fontSize: "16px" }}>
                {selectedSettlement?.start &&
                  "Desde: " +
                    moment(selectedSettlement?.start).format("DD") +
                    " de " +
                    `${capitalizeFirstLetter(moment(selectedSettlement?.start).format("MMMM"))}` +
                    " del " +
                    moment(selectedSettlement?.start).format("YYYY")}
              </Typography>
              <Typography color={theme.palette.primary.main} sx={{ fontWeight: "400", fontSize: "16px" }}>
                {selectedSettlement?.end &&
                  "Hasta: " +
                    moment(selectedSettlement?.end).format("DD") +
                    " de " +
                    `${capitalizeFirstLetter(moment(selectedSettlement?.end).format("MMMM"))}` +
                    " del " +
                    moment(selectedSettlement?.end).format("YYYY")}
              </Typography>
            </Box>
          </Box>
          <Cards />
          <MonthlySettlement />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default HistoryDialog;
