export const getIncumbentName = (market_sui_code: string) => {
  switch (market_sui_code) {
    case "443":
      return "Afinia";
    case "561":
      return "Celsia";
    case "163":
      return "Enerpereira";
    case "704":
      return "EPM";
    default:
      return "No se encontró el market_sui_code";
  }
};
