import {
  ArrowForwardIosOutlinedIcon,
  Button,
  Typography,
  enerbitColors,
} from "@enerbit/base";
import { Box } from "@mui/material";
import BodyComponent from "./BodyComponent";
import TitleComponent from "./TitleComponent";

type DataItem = {
  label: string;
  value?: number | string | Date | React.ReactNode;
  Icon: React.ElementType;
  showButtonItem?: ShowButtonItemProps;
};

interface ShowButtonItemProps {
  shouldShowButton: boolean;
  onClick: () => void;
}

interface ShowButtonBottomProps {
  Icon?: React.ElementType;
  textButton?: string;
  shouldShowButtonBottom: boolean;
  onClick?: () => void;
}

type SectionCardInformationProps = {
  background?: string;
  titleColor?: string;
  bodyColor?: string;
  titleText: string;
  data: DataItem[];
  IconTitle: React.ElementType;
  showButtonTop?: boolean;
  showButtonBottom?: ShowButtonBottomProps;
  onClick?: () => void;
};

const SectionCardInformation = ({
  background,
  titleColor,
  bodyColor,
  titleText,
  data,
  IconTitle,
  showButtonTop,
  showButtonBottom = { shouldShowButtonBottom: false, onClick: () => {} },
  onClick,
}: SectionCardInformationProps) => (
  <Box
    sx={{
      backgroundColor: background,
      padding: "1rem",
      borderRadius: "8px",
    }}
  >
    <TitleComponent
      color={titleColor}
      IconTitle={IconTitle}
      titleText={titleText}
      showButtonTop={showButtonTop}
      onClick={onClick}
    />
    <BodyComponent color={bodyColor} data={data} titleColor={titleColor} />

    {showButtonBottom.shouldShowButtonBottom && (
      <Box
        sx={{
          display: "flex",
          marginTop: "1rem",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Button
          onClick={showButtonBottom.onClick}
          sx={{
            display: "flex",
            width: "100%",
            padding: "0.3rem 0.3rem 0.3rem 0 !important",
            justifyContent: "space-between",
            minWidth: "0 !important",
            borderRadius: "8px !important",
            color: bodyColor,
            "&:hover": {
              background: "none",
              color: titleColor,
              "& .MuiTypography-root, & .MuiSvgIcon-root, & span": {
                background: "none",
                color: titleColor,
              },
            },
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {showButtonBottom.Icon && (
              <showButtonBottom.Icon
                sx={{
                  color: bodyColor,
                  fontSize: "12px",
                  marginRight: "0.2rem",
                }}
              />
            )}
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                marginRight: "2.0rem",
                color: bodyColor,
              }}
            >
              <Box
                component="span"
                sx={{
                  color: bodyColor,
                  fontWeight: "bold",
                  marginLeft: "7px",
                  fontSize: "0.875rem",
                }}
              >
                {showButtonBottom.textButton}
              </Box>
            </Typography>
          </Box>
          <Box
            sx={{
              background: "transparent",
              padding: "0.2rem",
              borderRadius: "15%",
              display: "flex",
            }}
          >
            <ArrowForwardIosOutlinedIcon
              sx={{ color: enerbitColors.neutral[700], fontSize: "0.7rem" }}
            />
          </Box>
        </Button>
      </Box>
    )}
  </Box>
);

export default SectionCardInformation;
