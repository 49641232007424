import {
  Box,
  CloseIcon,
  IconButton,
  Modal,
  Typography,
  enerbitColors,
} from "@enerbit/base";
import { useSelector } from "react-redux";
import { getTitleModal } from "../../helpers/information/labelsModal";
import { StateStorage } from "../../models/StateStorage";
import { FormChangeModal } from "./components/FormChangeModal";

export type ModalChangePlanProps = {
  open: boolean;
  handleClose?: (event: {}, reason: "backdropClick" | "escapeKeyDown") => void;
};

export const ModalChangePlan = ({
  open,
  handleClose,
}: ModalChangePlanProps) => {
  const { typeChangePlan } = useSelector((state: StateStorage) => state.usages);

  return (
    <Modal open={open} onClose={handleClose}>
      <Box className="modal-change-modal-plan">
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h5"
            color={enerbitColors.primary.main}
            align="center"
            fontWeight="bold"
          >
            {getTitleModal(typeChangePlan)}
          </Typography>
          <IconButton
            onClick={(_) => {
              if (handleClose) {
                handleClose({}, "backdropClick");
              }
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <FormChangeModal handleClose={handleClose} />
      </Box>
    </Modal>
  );
};
